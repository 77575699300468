<template>
  <app-header/>
  <Suspense>
    <template #default>
      <app-not-founds/>
    </template>
    <template #fallback>
      <app-loading/>
    </template>
  </Suspense>
  <app-footer/>
</template>

<script>

import { defineAsyncComponent, Suspense } from "vue";
import AppHeader from '../../components/layouts/AppHeader.vue';
import AppFooter from '../../components/layouts/AppFooter.vue';
import AppLoading from '../../components/Spinners/AppLoading.vue';
const AppNotFounds = defineAsyncComponent(() =>
  import("../../components/Pages/AppNotFounds.vue")
);
export default {
  name: "NotFounds",
  components: {
    Suspense,
    AppHeader,
    AppFooter,
    AppNotFounds,
    AppLoading,
  },
};
</script>

<style>
</style>